import React from 'react';

import { Layout, SEO } from 'components';
import { Apps } from 'containers';

const AppsPage = () => (
  <Layout>
    <SEO title='Apps' />
    <Apps />
  </Layout>
);

export default AppsPage;
