// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-apps-foobar-4423-js": () => import("./../src/pages/apps/foobar4423.js" /* webpackChunkName: "component---src-pages-apps-foobar-4423-js" */),
  "component---src-pages-apps-js": () => import("./../src/pages/apps.js" /* webpackChunkName: "component---src-pages-apps-js" */),
  "component---src-pages-apps-myl-js": () => import("./../src/pages/apps/myl.js" /* webpackChunkName: "component---src-pages-apps-myl-js" */),
  "component---src-pages-apps-waremete-js": () => import("./../src/pages/apps/waremete.js" /* webpackChunkName: "component---src-pages-apps-waremete-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-works-sec-js": () => import("./../src/pages/works/sec.js" /* webpackChunkName: "component---src-pages-works-sec-js" */)
}

