import React from 'react';

import { Layout, SEO } from 'components';
import { Myl } from 'containers';

const MylPage = () => (
  <Layout>
    <SEO title='Manage your Life' />
    <Myl />
  </Layout>
);

export default MylPage;
