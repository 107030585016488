import React from 'react';

import { Layout, SEO } from 'components';
import { Waremete } from 'containers';

const WaremetePage = () => (
  <Layout>
    <SEO title='waremete' />
    <Waremete />
  </Layout>
);

export default WaremetePage;
