const styles = ({ palette, typography }) => ({
  container: {
    listStyle: 'none',
    padding: 0,
    position: 'relative',
    flexWrap: 'wrap',
  },
  app: {
    border: 'none',
    padding: `0 ${typography.pxToRem(8)}`,
    marginBottom: '1em',
  },
  titleActive: {
    fontWeight: 400,
    fontSize: '1.05em',
    textDecoration: 'none',
    color: '#3b4e79',
    marginRight: `${typography.pxToRem(8)}`,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  titleInactive: {
    fontWeight: 400,
    fontSize: '1.05em',
    color: '#333',
    marginRight: `${typography.pxToRem(8)}`,
  },
  linkText: {
    paddingLeft: typography.pxToRem(8),
  },
  link: {
    border: 'none',
    margin: `${typography.pxToRem(8)} 0`,
    marginRight: `${typography.pxToRem(8)}`,
    textDecoration: 'none',
    color: palette.text.secondary,
    verticalAlign: 'middle',
  },
  team: {
    border: 'none',
    margin: `${typography.pxToRem(8)} 0`,
    textDecoration: 'none',
    color: palette.text.secondary,
    verticalAlign: 'middle',
    position: 'relative',
    '&::before': {
      position: 'absolute',
      transition: 'all 0.3s ease 0s',
      opacity: 0,
      content: '',
      border: '10px solid transparent',
      borderBottomColor: '#000',
      bottom: '10px',
      left: '10px',
    },
    '&::after': {
      position: 'absolute',
      transition: 'all 0.3s ease 0s',
      opacity: 0,
      content: 'attr(data-tooltip)',
      display: 'block',
      padding: '5px',
      background: '#000',
      color: '#fff',
      borderRadius: '5px',
      width: '120px',
      bottom: '10px',
      left: '-50px',
      fontSize: '0.85em',
      fontWeight: 400,
      textAlign: 'center',
    },
    '&:hover::before': {
      bottom: '10px',
      opacity: 1,
    },
    '&:hover::after': {
      bottom: '30px',
      opacity: 1,
    },
  },
});

export default styles;
