import { FaGithub, FaTwitter, FaCompass, FaBlog } from 'react-icons/fa';

const content = {
  title: 'About.',
  subtitle: '',
  links: [
    {
      to: 'https://github.com/4423',
      text: '4423',
      Icon: FaGithub,
      newTab: true,
    },
    {
      to: 'https://twitter.com/neo4423',
      text: 'Neo4423',
      Icon: FaTwitter,
      newTab: true,
    },
    {
      to: 'https://connpass.com/user/Neo4423/',
      text: 'Neo4423',
      Icon: FaCompass,
      newTab: true,
    },
    {
      to: 'https://tech.4423.ch',
      text: 'tech blog',
      Icon: FaBlog,
      newTab: true,
    },
    // {
    //   to: 'https://blog.4423.ch',
    //   text: 'blog',
    //   Icon: FaBlog,
    //   newTab: true,
    // },
  ],
};

export default content;
