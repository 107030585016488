import React from 'react';
import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';

import { Animated, AppList } from 'components';

import styles from './style';
import content from './content';

const { title, subtitle, data } = content;

type Props = {
  classes: Object,
};

const Apps = ({ classes }: Props) => (
  <>
    <Fade in timeout={1000}>
      <Typography variant='h1' color='textPrimary'>
        <Animated>{title}</Animated>
      </Typography>
    </Fade>
    <Typography variant='h3' color='textPrimary'>
      {subtitle}
    </Typography>
    <div className={classes.container}>
      {data.map(({ category, apps }, index) => (
        <div key={index}>
          <h2>{category}</h2>
          <AppList apps={apps} />
        </div>
      ))}
    </div>
  </>
);

export default withStyles(styles)(Apps);
