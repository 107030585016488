import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import theme from 'styles/theme';
import 'styles/app.css'
require("prismjs/themes/prism-tomorrow.css")

window.jQuery = window.$ = require('jquery');

export const wrapRootElement = ({ element }) => {
 return (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    {element}
  </ThemeProvider>
 );
};
