import React from 'react';
import Typography from '@material-ui/core/Typography';
import { FlexSlider } from 'components';

const imageUrls = [
  'https://live.staticflickr.com/65535/47120966494_3226a91cdc_z.jpg',
  'https://live.staticflickr.com/65535/47120961874_20e8cd0b48_z.jpg',
];

const Cybersecurity = () => (
  <div className='app'>
    <Typography variant='h2' color='textPrimary'>
      Cybersecurity Learning Platform
    </Typography>
    <p>
    サイバーセキュリティにおける攻撃と防御が可能な演習システムを開発しました。
    実際の企業を模したイントラネットを4台の Raspberry Pi と Cisco のネットワーク機器を使用して構築しています。
    </p>
    <div className='images'>
      <FlexSlider imageUrls={imageUrls}></FlexSlider>
    </div>
    <div className='content'>
      <h3>できること</h3>
      <ul>
        <li>SQL Injection や XSS などの脆弱性を使用した攻撃</li>
        <li>ルータの ACL や Firewall、IDS/IPS を用いた防御</li>
        <li>ログの可視化</li>
        <li>その他 Linux サーバ上でできることすべて</li>
      </ul>
      <h3>使用技術</h3>
      <ul>
        <li>Webサーバ : PHP, MariaDB, Apache</li>
        <li>ディレクトリサーバ : LDAP</li>
        <li>DNSサーバ : BIND</li>
        <li>FW/IDS/IPSサーバ : firewalld, Snort</li>
      </ul>
      <h3>成果物</h3>
      <ul>
          <li><a href="https://github.com/4423/exploit_site" target="_blank" rel="noopener noreferrer">exploit_site | GitHub</a></li>
          <li>演習システムを構築・利用するための完全な資料（約40ページ） [<a href="./intranet.pdf" target="_blank" rel="noopener noreferrer">PDF</a>]</li>
      </ul>
    </div>
    <footer>
      <a href='/'>4423.ch</a>
    </footer>
  </div>
);

export default Cybersecurity;
