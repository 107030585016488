import React from 'react';

const content = {
  title: 'WaremeteGadget',
  imageUrls: [
      './img/ss1.gif',
      './img/ss2.png',
      './img/ss3.png',
    ],
  html: 
    <div>
        <h2>概要</h2>
        <p>
            PCゲーム「失われた未来を求めて」に登場するキャラクターを、デスクトップ上に配置するアプリケーションです。
        </p>

        <h2>特徴</h2>
        <ul>
            <li>キャラクターの表情や服装を自分で組み合わせることが出来ます。</li>
            <li>“目パチ”に対応しています。</li>
        </ul>

        <h2>動作環境</h2>
        <div>
            <table>
                <tr>
                    <td>OS</td>
                    <td>Windows7/8.1 (32ビット/64ビット)</td>
                </tr>
                <tr>
                    <td>システム要件</td>
                    <td><a href="http://www.microsoft.com/ja-jp/download/details.aspx?id=30653">.NET Framework 4.5</a>以上がインストールされていること</td>
                </tr>            
            </table>
        </div>

        <h2>ダウンロード</h2>
        <p className='important'>
            ※ 実行には「失われた未来を求めて」ゲーム本編のデータが必要です。<br/>                
            ※ 使用方法等は同梱のReadMe.txtファイルを参照して下さい。
        </p>
        <div>
            <table>
                <thead>
                    <tr>
                        <th>バージョン</th>
                        <th>更新履歴</th>
                        <th>公開日時</th>
                        <th>ファイル</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>0.1（最新）</td>
                        <td>初回リリース</td>
                        <td>2014/11/30</td>
                        <td><a href="./files/WaremeteGadget_0.1.zip" onclick="ga('send','event','download','zip','WaremeteGadget_0.1.zip');">ダウンロード</a></td>
                    </tr>         
                </tbody>
            </table>
        </div>
        
        <h2>使用ライブラリ</h2>
        <ul>
            <li>LINQtoCSV</li>
        </ul>
        
        <h2>実装予定</h2>
        <ul>
            <li>タスクトレイへの格納機能</li>
            <li>ボイス再生及び口パク</li>
            <li>etc...</li>
        </ul>
    </div>,
};

export default content;
