import React from 'react';
import $ from 'jquery';
import 'flexslider/flexslider.css';

import styles from './style';

class FlexSlider extends React.Component {
  componentDidMount() {
    this.flexslider = require('flexslider/jquery.flexslider');
    this.$el = $(this.el);
    this.$el.flexslider({
      slideshowSpeed: 5000
    });
  }

  componentWillUnmount() {
    this.$el.flexslider('destroy');
  }

  render() {
    return (
      <div className='flexslider' ref={el => this.el = el} style={styles.flexslider}>
        <ul className='slides'>
          {this.props.imageUrls.map((src, index) => (
            <li key={index}>
              <img src={src} alt=''/>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default FlexSlider;
