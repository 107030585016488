import React from 'react';

import { Layout, SEO } from 'components';
import { Foobar4423 } from 'containers';

const Foobar4423Page = () => (
  <Layout>
    <SEO title='foobar4423' />
    <Foobar4423 />
  </Layout>
);

export default Foobar4423Page;
