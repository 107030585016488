import React from 'react';

const content = {
  title: 'foobar4423',
  imageUrls: [
      './img/foobar2000_nowplaying1.jpg',
      './img/foobar2000_nowplaying2.jpg',
      './img/foobar2000_nowplaying3.jpg',
    ],
  html: 
    <div>
        <h2>概要</h2>
        <p>
            音楽プレイヤー「<a href="https://www.foobar2000.org/">foobar2000</a>」の再生情報（なうぷれ）をTwitterに投稿するアプリケーションです。
        </p>

        <h2>特徴</h2>
        <ul>
            <li>自動投稿に対応しています。</li>
            <li>投稿文の出力フォーマットを指定可能です。</li>
            <li>投稿前の文章を変更することが出来ます。</li>
            <li>通知領域に格納した状態での投稿が可能です。</li>
        </ul>

        <h2>動作環境</h2>
        <div>
            <table>
                <tbody>
                    <tr>
                        <td>OS</td>
                        <td>Windows7/10 (64ビット)</td>
                    </tr>
                    <tr>
                        <td>システム要件</td>
                        <td>
                            <a href="http://www.microsoft.com/ja-jp/download/details.aspx?id=30653">.NET Framework 4.5</a>がインストールされていること<br/>
                            <a href="http://foosion.foobar2000.org/0.9/">COM Automation server</a>がインストールされていること
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <p className="important">※ COM Automation server のインストールは foobar2000 を必ず終了した状態で行ってください。</p>

        <h2>ダウンロード</h2>
        <div>
            <table>
                <thead>
                    <tr>
                        <th>バージョン</th>
                        <th>更新履歴</th>
                        <th>公開日時</th>
                        <th>ファイル</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>0.4.1（最新）</td>
                        <td>エラーハンドリングの強化</td>
                        <td>2021/02/19</td>
                        <td><a href="https://github.com/4423/foobar4423/releases/download/v0.4.1/foobar4423-0.4.1.zip" onClick="ga('send', 'event', 'download', 'zip', 'foobar4423_0.4.1.zip');">ダウンロード</a></td>
                    </tr>
                    <tr>
                        <td>0.4</td>
                        <td>Twitterに接続できない不具合の修正など</td>
                        <td>2020/08/08</td>
                        <td><a href="https://github.com/4423/foobar4423/releases/download/v0.4/foobar4423-0.4.zip" onClick="ga('send', 'event', 'download', 'zip', 'foobar4423_0.4.zip');">ダウンロード</a></td>
                    </tr>
                    <tr>
                        <td>0.3</td>
                        <td>不具合の修正・出力フォーマットの機能強化など</td>
                        <td>2015/03/04</td>
                        <td><a href="./files/foobar4423_0.3.zip" onClick="ga('send', 'event', 'download', 'zip', 'foobar4423_0.3.zip');">ダウンロード</a></td>
                    </tr>
                    <tr>
                        <td>0.2</td>
                        <td>投稿中に画面が固まる現象の解決</td>
                        <td>2014/03/21</td>
                        <td><a href="./files/foobar4423_0.2.zip" onClick="ga('send', 'event', 'download', 'zip', 'foobar4423_0.2.zip');">ダウンロード</a></td>
                    </tr>
                    <tr>
                        <td>0.1</td>
                        <td>初回リリース</td>
                        <td>2014/02/15</td>
                        <td><a href="./files/foobar4423_0.1.zip" onClick="ga('send', 'event', 'download', 'zip', 'foobar4423_0.1.zip');">ダウンロード</a></td>
                    </tr>
                </tbody>
            </table>
        </div>

        <h2 id="usage">使用方法</h2>
        <h3>初期設定</h3>
        <ol>
            <li className="li_head">
                ファイルの展開
                <ol>
                    <li>ダウンロードしたzipファイルの中身を全て、任意のフォルダに展開します。</li>
                    <li>`foobar4423.exe` が実行ファイルです。</li>
                </ol>
            </li>
            <li className="li_head">
                Twitterの認証
                <ol>
                    <li>Twitterアカウントと連携するために、`File(F)`→`Authentication(A)`→`Authorize` をクリックします。</li>
                    <li>TwitterのWebページで表示されるPINコードを foobar4423 に入力し、`Complete` ボタンをクリックします。</li>
                    <li>自分のアカウント名が表示されれば認証は成功です。</li>
                </ol>
            </li>
        </ol>
        <br/>
        <details>
            <summary>バージョン0.2以前には追加の設定が必要です。</summary>
            <ol>
                <li className="li_head">
                    foobar2000との連携(foobar4423側)
                    <ol>
                        <li>"File(F)"→"Config(C)"で"foobar2000.exeの場所"を指定して下さい。場所の指定はテキストボックス右側の"..."ボタンから行えます。</li>
                        <li>設定を保存する場合は"Save"ボタンを、破棄する場合は"Cancel"ボタンをクリックして下さい。</li>
                    </ol>
                </li>
                <li className="li_head" id="windowtitle">
                    foobar2000との連携(foobar2000側)
                    <ol>
                        <li>
                            foobar4423はfoobar2000の<a href="http://ja.wikipedia.org/wiki/タイトルバー" target="_blank" rel="noopener noreferrer">タイトルバー</a>から曲情報を取得します。
                            そのため、<span className="important">foobar2000のタイトルバーが規定のフォーマット</span>である必要があります。
                        </li>
                        <li>ここでいう規定のフォーマットとは、foobar2000インストール時の初期設定のフォーマットのことを指します。</li>
                        <li>
                            連携を保証するために、foobar2000の設定画面から"Default User Interface"→"Window Title"のフォーマットを確認して下さい。
                            <div className='gatsby-highlight' data-language='bash'><pre className='language-bash'><code className='language-bash'>
                                [%album artist% - ]['['%album%[ CD%discnumber%][ #%tracknumber%]']' ]%title%[ '//' %track artist%]
                            </code></pre></div>
                            上記のようであれば問題ありません(<a href="http://skillcode.blog61.fc2.com/blog-entry-46.html" target="_blank" rel="noopener noreferrer">参考ページ</a>)。
                        </li>
                    </ol>
                </li>
            </ol>
        </details>

        <h3>Twitterへの投稿</h3>
        <p className="important">※ ここからの操作は foobar2000 で曲が再生されていることを前提としています。</p>
        <ol>
            <li>
                `Get NowPlaying` ボタンを押して、foobar2000で再生している曲情報を取得します。
                取得した情報は<a href="#format">出力フォーマット</a>に従って生成され、テキストボックスに表示されます。
            </li>
            <li>`Post` ボタンでテキストボックスの内容をツイートします。</li>
            <li>ツイート内容を変更する場合は、テキストボックスに生成された文章を書き換えて下さい。</li>
        </ol>

        <h3 id="format">出力フォーマット</h3>
        <p>
            出力フォーマットは、`File(F)`→`Config(C)` の `NowPlaying format` で設定できます。
            出力フォーマットの詳細は以下の表のとおりです。
        </p>
        <div>
            <h4>変数</h4>
            <table>
                <thead>
                    <tr>
                        <th>変数名</th>
                        <th>意味</th>
                        <th>例</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>$SONG$</td>
                        <td>曲名</td>
                        <td>俺ら東京さ行ぐだ</td>
                    </tr>
                    <tr>
                        <td>$ARTIST$</td>
                        <td>歌手名</td>
                        <td>吉幾三</td>
                    </tr>
                    <tr>
                        <td>$ALBUM$</td>
                        <td>アルバム名</td>
                        <td>昭和の演歌 7 (昭和58-60年)</td>
                    </tr>
                    <tr>
                        <td>$ALBUM_ARTIST$</td>
                        <td>アルバムアーティスト名</td>
                        <td>様々なアーティスト</td>
                    </tr>
                    <tr>
                        <td>$TRACK_NUMBER$</td>
                        <td>トラック番号</td>
                        <td>7</td>
                    </tr>
                    <tr>
                        <td>$DISC_NUMBER$</td>
                        <td>ディスク番号</td>
                        <td>2</td>
                    </tr>
                </tbody>
            </table>

            <h4>制御構文</h4>
            <table>
                <thead>
                    <tr>
                        <th style={{width: '7em'}}>構文</th>
                        <th>意味</th>
                        <th>例</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{'<...変数...>'}</td>
                        <td>{'<>で囲まれた変数の値が空の場合は、<>内の文字を一切出力しません'}</td>
                        <td>{'< via $ALBUM$>でALBUM情報がない場合は、viaという文字も出力されない'}</td>
                    </tr>
                </tbody>
            </table>
            <p>
                制御構文は仮実装のため、予期せぬ動作をする場合があります。<br></br>
                例えば、<code className='language-text'>{'<変数1　変数2>'}</code> のように括弧内に変数を2つ以上配置した場合の動作は保証しません。<br></br>
                また、<code className='language-text'>{'< - <変数>>'}</code> のように括弧が二重になる配置も正しく動作しない可能性があります。
            </p>
        </div>

        <h3>その他の機能</h3>
        <ul>
            <li>
                <h4>タスクバーへの格納</h4>
                <p>
                    最小化時はタスクバーに格納されます。
                    タスクバーのアイコンを右クリックすることで、ツイートすることも可能です。
                </p>
            </li>
            <li>
                <h4>バルーン通知</h4>
                <p>
                    `File(F)`→`Config(C)` の `Enable toast notification` にチェックを入れることで、ツイート時に通知を表示します。
                </p>
            </li>
            <li>
                <h4>自動投稿</h4>
                <p>
                    `Auto Post` にチェックを入れることで、再生曲変更時に自動的にツイートを行います。
                </p>
            </li>
        </ul>

        <h2>起動しないとき</h2>
        <h4>Faild to initialize COM Automation server と表示される</h4>
        <p>バージョン0.4.1以降では、foobar2000 との接続に失敗する場合に、次のようなエラーメッセージが表示されます。</p>
        <img style={{width: "550px"}} src="/apps/foobar4423/img/errormessage.jpg" alt='error'></img>

        <p>原因： このエラーの多くは COM Automation server が正しくインストールされていないために引き起こされます。</p>
        <p>対処方法：</p>
        <ol>
            <li>
                PC を一度再起動してから、COM Automation server を再インストールします（単にインストーラーを再実行します）。
                このとき、foobar2000 は起動しないでください。
                foobar2000 を起動していると、COM Automation server を正しくインストールできません。
            </li>
            <li>
                インストールが完了したら、foobar2000 を起動します。
                `File` → `Preferences` → `Tools` → `COM Automation server` と選択し、ステータスが Running であることを確認します。
                <img style={{width: "750px"}} src="/apps/foobar4423/img/preference.jpg" alt='preference'></img>
            </li>
            <li>
                foobar4423 を起動します。
                それでも起動しない場合は、お手数ですが作者（<a href="https://twitter.com/Neo4423">@Neo4423</a>）までご連絡を頂けると幸いです。
            </li>
        </ol>

        <h2>使用ライブラリ</h2>
        <ul>
            <li><a href="https://github.com/chitoku-k/NowPlayingLib">NowPlayingLib</a></li>
            <li><a href="https://github.com/CoreTweet/CoreTweet">CoreTweet</a></li>
        </ul>

        <h2>ソースコード</h2>
        <p>
            コントリビュートを歓迎します。
        </p>
        <a href="https://github.com/4423/foobar4423">
            <img src="https://gh-card.dev/repos/4423/foobar4423.svg" alt='card'></img>
        </a>
    </div>,
};

export default content;
