import React from 'react';
import { withStyles } from '@material-ui/styles';

import styles from './style';

type Props = {
  classes: Object,
  apps: [],
};

const AppList = ({ classes, apps }: Props) => (
  <div>
    <ul className={classes.container}>
      {apps.map(({ title, description, technologies, image, url, isAvailable, newTab, team, src }, index) => (
        <li key={index}>
          <div className={classes.app}>
            {isAvailable
              ? <a className={classes.titleActive} href={url}>{title}</a>
              : <span className={classes.titleInactive}>{title}</span>
            }
            {src !== undefined &&
            <a
              href={src.url}
              target={newTab ? '_blank' : '_self'}
              className={classes.link}>
              <src.Icon />
            </a>
            }
            {team !== undefined &&
            <span
              data-tooltip={team.num + '-person team'}
              className={classes.team}>
              <team.Icon />
            </span>
            }
            <div>{description}</div>
          </div>
        </li>
      ))}
    </ul>
  </div>
);

export default withStyles(styles)(AppList);
