import React from 'react';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';

import { Animated } from 'components';
import { FlexSlider } from 'components';

import content from './content';

const { title, imageUrls, html } = content;

const Foobar4423 = () => (
  <div className='app'>
    <div className='title'>
      <Fade in timeout={1000}>
        <Typography variant='h1' color='textPrimary'>
          <Animated>{title}</Animated>
        </Typography>
      </Fade>
    </div>
    <div className='images'>
      <FlexSlider imageUrls={imageUrls}></FlexSlider>
    </div>
    <div className='content'>
      {html}
    </div>
    <footer>
      <a href='/'>4423.ch</a>
    </footer>
  </div>
);

export default Foobar4423;
