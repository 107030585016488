import React from 'react';
import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';

import styles from './style';

type Props = {
  classes: Object,
  data: Object,
};

const BlogPost = ({ classes, data }: Props) => (
  <div className={classes.container}>
    <Typography variant='h2' color='textPrimary'>
      {data.frontmatter.title}      
    </Typography>
    <Typography variant='date1'>
      {data.frontmatter.date}
    </Typography>
    <Typography
      variant='body1'
      dangerouslySetInnerHTML={{
        __html: data.html,
      }}
    />
  </div>
);

export default withStyles(styles)(BlogPost);
