import { FaGithub, FaUserFriends } from 'react-icons/fa';

const content = {
  title: 'Apps.',
  subtitle: '',
  data: [
    {
      category: 'Windows',
      apps: [
        {
          title: 'ErogeDiary',
          description: 'Eroge tracking application.',
          technologies: ['C#', 'WPF', 'EntityFramework'],
          image: "",
          url: 'https://erogediary.4423.ch/',
          isAvailable: true,
          newTab: true,
          src: {
            url: 'https://github.com/4423/ErogeDiary',
            Icon: FaGithub,
          },
        },
        {
          title: 'foobar4423',
          description: '#nowplaying on foobar2000.',
          technologies: ['C#', 'Forms', 'COM'],
          image: './apps/foobar4423/img/foobar2000_nowplaying1.png',
          url: './foobar4423',
          isAvailable: true,
          newTab: true,
          src: {
            url: 'https://github.com/4423/foobar4423',
            Icon: FaGithub,
          },
        },
        {
          title: 'WaremeteGadget',
          description: 'Desktop mascot featuring characters from "失われた未来を求めて".',
          technologies: ['C#', 'WPF'],
          image: './apps/waremete/img/ss1.gif',
          url: './waremete',
          isAvailable: true,
          newTab: true,
          src: {
            url: 'https://github.com/4423/WaremeteGadget',
            Icon: FaGithub,
          },
        },
        {
          title: 'Manage your Life',
          description: 'Automatically records your computer usage and graphically reports.',
          technologies: ['C#', 'WPF', 'SQLServer'],
          image: './apps/myl/img/img1.png',
          url: './myl',
          isAvailable: true,
          newTab: true,
          src: {
            url: 'https://github.com/4423/Manage-your-Life',
            Icon: FaGithub,
          },
        },
        {
          title: 'AR 電脳コイル',
          description: 'Unity game which imitates the world of the anime 電脳コイル.',
          technologies: ['C#', 'WPF', 'SQLServer'],
          image: './apps/myl/img/img1.png',
          url: './myl',
          isAvailable: false,
          newTab: true,
        },
      ],
    },
    {
      category: 'Web',
      apps: [
        {
          title: 'Puniket Circle Viewer',
          description: 'Refined circle placements for Puniket.',
          technologies: ['Vue.js'],
          image: '',
          url: 'https://puniket-circle-viewer.netlify.app',
          isAvailable: true,
          newTab: true,
          src: {
            url: 'https://github.com/4423/puniket-circle-viewer',
            Icon: FaGithub,
          },
        },
        {
          title: 'アイドルマスターシンデレラガールズ 楽曲検索',
          description: 'Search for songs of THE IDOLM@STER CINDERELLA GIRLS by taking the union or intersection of characters\' discographies.',
          technologies: ['Vue.js'],
          image: '',
          url: 'https://cg-songs.vercel.app/',
          isAvailable: true,
          newTab: true,
          src: {
            url: 'https://github.com/4423/cg-songs',
            Icon: FaGithub,
          },
        },
        {
          title: 'JSON of THE IDOLMASTER CINDERELLA GIRLS',
          description: 'JSON scraped from Nicovideo Encyclopedia about THE IDOLM@STER CINDERELLA GIRLS.',
          technologies: ['GitHub Actions', 'Python'],
          image: '',
          url: 'https://github.com/4423/cg-data',
          isAvailable: true,
          newTab: true,
          src: {
            url: 'https://github.com/4423/cg-data',
            Icon: FaGithub,
          },
        },
        {
          title: 'Cybersecurity Learning Platform',
          description: 'Supports to attack itself with exploits such as SQL Injection and defend itself with IPS.',
          technologies: ['PHP', 'MySQL', 'Snort', 'BIND'],
          image: '',
          url: '../works/sec',
          isAvailable: true,
          newTab: true,
          team: {
            num: 2,
            Icon: FaUserFriends,
          },
          src: {
            url: 'https://github.com/4423/exploit_site',
            Icon: FaGithub,
          },
        },
        {
          title: '0ngen',
          description: 'Show ongen files on Azure Storage.',
          technologies: ['Vue.js', 'Docker', 'Azure', 'Firebase'],
          image: '',
          url: 'https://tech.4423.ch/0ngen-2018',
          isAvailable: true,
          newTab: true,
          src: {
            url: 'https://github.com/4423/0ngen-webapp',
            Icon: FaGithub,
          },
        },
        {
          title: 'NAVI',
          description: 'Navigate you inside my school.',
          technologies: ['C#', 'ASP.NET', 'Azure', 'jQuery'],
          image: '',
          url: '',
          isAvailable: false,
          newTab: true,
          team: {
            num: 3,
            Icon: FaUserFriends,
          },
          src: {
            url: 'https://github.com/4423/FujitsuChizai-Web',
            Icon: FaGithub,
          },
        },
        {
          title: '東京メトロ de ぶらり旅',
          description: 'Trip using the Tokyo Metro and Google Street View.',
          technologies: ['PHP', 'jQuery'],
          image: '',
          url: 'https://youtu.be/SgY44YIYGtE',
          isAvailable: true,
          newTab: true,
          team: {
            num: 2,
            Icon: FaUserFriends,
          },
        },
      ]
    },
    {
      category: 'Android',
      apps: [
        {
          title: 'Source Code Viewer',
          description: 'Syntax-highlights source codes.',
          technologies: ['Java'],
          image: '',
          url: '',
          isAvailable: false,
          newTab: true,
          src: {
            url: 'https://github.com/4423/SourceCodeViewer',
            Icon: FaGithub,
          },
        },
        {
          title: 'Go Lab',
          description: 'Sample app using Mobile Blazor Bindings.',
          technologies: ['C#', 'Blazor'],
          image: '',
          url: '',
          isAvailable: false,
          newTab: true,
          src: {
            url: 'https://github.com/4423/GoLab',
            Icon: FaGithub,
          },
        },
      ],
    },
  ],
};

export default content;
