import React from 'react';

const styles = {
    message: {
        marginTop: '4em',
        marginBottom: '3em',
        textAlign: 'center',
        fontSize: '2em',
    },
    innerTable: {
        margin: '1em 0',
    },
};

const content = {
  title: 'Manage your Life',
  imageUrls: [
      './img/img1.png',
      './img/img2.png',
      './img/img3.png',
      './img/img4.png',
      './img/img5.png',
      './img/img6.png',
      './img/img7.png',
      './img/img8.png',
      './img/img9.png',
      './img/img10.png',
    ],
  html: 
    <div>
        <div style={styles.message}>
            あなたのパソコン生活、見直してみませんか？
        </div>

        <h2>概要</h2>
        <p>
            Manage your Lifeは、コンピュータの利用状況を可視化することで、コンピュータを使った生活の見直しをサポートします。
        </p>

        <h2>特徴</h2>
        <ul>
            <li>モダンなデザイン。</li>
            <li>蓄積したコンピュータの利用状況を、グラフを用いて見やすく提示します。</li>
            <li>使い過ぎのアプリケーションは警告されます。</li>
            <li>利用状況をTwitterに公開することが出来ます。</li>
            <li>タスクトレイへの格納に対応しています。</li>
        </ul>

        <h2>動作環境</h2>
        <div>
            <table>
                <tbody>
                    <tr>
                        <td>OS</td>
                        <td>Windows7/8.1 (32ビット/64ビット)</td>
                    </tr>
                    <tr>
                        <td>システム要件</td>
                        <td>
                            ネットワークに接続されていること<br/>
                            <a href="http://www.microsoft.com/ja-jp/download/details.aspx?id=30653">.NET Framework 4.5</a><br/>
                            <a href="http://www.microsoft.com/ja-jp/download/details.aspx?id=29062">Microsoft SQL Server 2012 Express</a> がインストールされていること
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <h2>ダウンロード</h2>
        <div>
            <table>
                <thead>
                    <tr>
                        <th>バージョン</th>
                        <th>更新履歴</th>
                        <th>公開日時</th>
                        <th>ファイル</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1.0（最新）</td>
                        <td>初回リリース</td>
                        <td>2015/03/09</td>
                        <td><a href="./files/Manage your Life_1.0.zip" onclick="ga('send', 'event', 'download', 'zip', 'Manage your Life_1.0.zip');">ダウンロード</a></td>
                    </tr>
                </tbody>
            </table>
        </div>

        <h2>使用ライブラリ</h2>
        <ul>
            <li>ModernUIforWPF</li>
            <li>ModernUI(Metro)Charts</li>
            <li>CoreTweet</li>
            <li>Newtonsoft.Json</li>
            <li>WPFToolkitDataVisualizationControls</li>
        </ul>

        <h2>インストール</h2>
        <p>
            Manage your Lifeを使うためには、以下の2つのソフトウェアをインストールしておく必要があります。
        </p>
        <ul>
            <li><a href="http://www.microsoft.com/ja-jp/download/details.aspx?id=29062">Microsoft® SQL Server® 2012 Express</a> (SqlLocalDB.msi)</li>
            <li><a href="http://www.microsoft.com/ja-jp/download/details.aspx?id=30653">.NET Framework 4.5</a></li>
        </ul>

        <h4>Microsoft® SQL Server® 2012 Expressのインストール方法</h4>
        <ol>
            <li><a href="http://www.microsoft.com/ja-jp/download/details.aspx?id=29062">ダウンロードページ</a>から、自身のOSに従って
                <ul>
                    <li>JPN\x64\SqlLocalDB.msi (64ビットOSの場合)</li>
                    <li>JPN\x86\SqlLocalDB.msi (32ビットOSの場合)</li>
                </ul>
                のどちらかのファイルをダウンロードします。</li>
            <li>インストールウィザードに沿ってインストールを進めます。</li>
        </ol>

        <h4>.NET Framework 4.5のインストール方法</h4>
        <ol>
            <li><a href="http://www.microsoft.com/ja-jp/download/details.aspx?id=30653">ダウンロードページ</a>からdotNetFx45_Full_setup.exeをダウンロードします。</li>
            <li>ダウンロードしたファイルを実行し、インストールウィザードに沿ってインストールを進めて下さい。</li>
            <li>インストール作業を終えたら、パソコンを再起動して下さい。</li>
        </ol>

        <h4>Manage your Lifeのインストール方法</h4>            
        <ol>
            <li>ダウンロードしたzipファイルの中身を全て、任意のフォルダに展開します。</li>
            <li>"Manage your Life.exe"が実行ファイルです。</li>
        </ol>


        <h2 id="usage">使用方法</h2>
        <ol>
            <li>
                実行ファイル(Manage your Life.exe)を起動します。
                起動するとスプラッシュウィンドウ（起動画面）が表示され、しばらくするとメインウィンドウが表示されます。
                <p className='important'>
                    ※初回起動時はデータベースとの接続に時間がかかり、起動途中で強制終了してしまう場合があります。
                    この場合、数秒後にもう一度起動しなおすことで解決する場合があります。
                </p>
                <img src="./img/img0.png" alt='splash window'/>
            </li>
            <li>
                メインウィンドウが立ち上がると、アプリケーションの登録を待機する状態になります。
                普段パソコンを使うように、何か適当なアプリケーションを起動・使用すると、その使用状況が本アプリケーションに登録されます。
                <img src="./img/img1.png" alt='main window'/>
            </li>
            <li>
                Manage your Lifeには4つのタブがあります。
                タブごとの機能は以下の表の通りです。
                <div>
                    <table style={styles.innerTable}>
                        <thead>
                            <tr>
                                <th>タブ名</th>
                                <th>機能</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>HOME</td>
                                <td>今日の使用状況に関する情報を表示します</td>
                            </tr>
                            <tr>
                                <td>APPLICATION</td>
                                <td>データベースに登録されているアプリケーション情報を確認・編集することが出来ます</td>
                            </tr>
                            <tr>
                                <td>TIMELINE</td>
                                <td>ユーザーが指定した条件に基づいて、ある日のアプリケーションの使用頻度・使用タイミングなどを表示します</td>
                            </tr>
                            <tr>
                                <td>STATISTICS</td>
                                <td>ユーザーが指定した条件に基づいて、ある期間のアプリケーションの使用時間や、
                                アプリケーションを最後に使用した日時・使用開始日などを表示します</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </li>
            <li>
                メインウィンドウの画面上部右側のSETTINGSより、このアプリケーションの設定を行うことが出来ます。
                アプリケーション使用時間超過の警告設定などはここで行います。
            </li>
            <li>
                最小化ボタンを押すと、タスクトレイに格納されます。
                画面を復元するときはタスクトレイにある時計アイコンをダブルクリックして下さい。
            </li>
            <li>
                終了ボタンを押すとこのアプリケーションを終了します。
                終了時には、今日の使用状況が表示されます。
                その結果はTwitterに投稿することが出来ます。
            </li>
        </ol>
    </div>,
};

export default content;
